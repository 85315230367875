<template>
  <div>
    <empresa-combo
      class="w-25"
      :comTodos="true"
      :selected="empresa.cod_empresa"
      :selecionar="selecionar"
    />
    <div class="text-right" v-if="loadingUpdatesNoTable || loading">
      <b-spinner small class="align-baseline"></b-spinner> carregando...
    </div>
    <b-table
      :items="listaShow"
      :fields="campos"
      responsive="lg"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      :busy="loading"
    >
      <template #table-busy class="text-center">
        <b-spinner />
      </template>

      <template #cell(situacao)="row">
        <div class="text-nowrap align-baseline">
          <b-form-checkbox
            v-model="row.item.situacao"
            value="1"
            unchecked-value="0"
            name="check-button"
            switch
            @change="salvar(row.item, true)"
            class="mt-2"
          />
          <span>{{ row.item.situacao == 1 ? "Ativo" : "Inativo" }}</span>
        </div>
      </template>

      <template #cell(tipo)="row">
        <b-form-group class="text-center">
          <b-form-select
            :options="grupos"
            v-model="row.item.tipo"
            @change="salvar(row.item, true)"
          />
        </b-form-group>
      </template>

      <template #cell(empresaRazaoSocial)="row">
        <b-form-group class="text-center">
          <b-form-select
            :options="empresasDisponiveis"
            v-model="row.item.cod_empresa"
            @change="salvar(row.item, true, true)"
          />
        </b-form-group>
      </template>

      <template #cell(caixaNome)="row">
        <b-form-select
          :options="caixasFromEmrpesa(row.item.cod_empresa)"
          v-model="row.item.cod_caixa"
          @change="salvar(row.item, true)"
        />
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-2 text-nowrap"
          :disabled="loadingUpdatesNoTable"
          @click="editar(row.item)"
          ><i class="fa fa-edit" v-if="!loadingUpdatesNoTable"></i>
          <b-spinner v-if="loadingUpdatesNoTable" small></b-spinner>
          Editar</b-button
        >
        <b-button
          size="sm"
          variant="danger"
          class="mr-2 text-nowrap"
          :disabled="loadingUpdatesNoTable"
          @click="excluir(row.item)"
          ><i class="fa fa-trash" v-if="!loadingUpdatesNoTable"></i>
          <b-spinner v-if="loadingUpdatesNoTable" small></b-spinner>
          Excluir</b-button
        >
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
      aria-controls="my-table"
    ></b-pagination>

    <b-modal
      id="modal-usuarios"
      hide-footer
      content-class="prodModal"
      :title="`Novo usuário`"
      no-fade
    >
      <UsuariosForm
        @salvou="carregar()"
        :usuarioEdit="usuarioEdit"
        ref="usuarioForm"
      />
    </b-modal>
  </div>
</template>

<script>
import UsuariosLib from "@/libs/UsuariosLib";
import UsuariosForm from "@/components/Usuarios/UsuariosForm";
import EmpresaCombo from "../empresa/EmpresaCombo.vue";

export default {
  components: {
    UsuariosForm,
    EmpresaCombo,
  },
  props: {
    filtro: String,
  },
  data() {
    return {
      loading: false,
      loadingUpdatesNoTable: false,
      lista: [],
      listaShow: [],
      usuarioEdit: {},
      campos: [
        {
          key: "nome",
          label: "Nome",
          sortable: true,
          class: "text-left text-nowrap",
        },
        {
          key: "usuario",
          label: "Usuário",
          sortable: true,
          class: "text-left text-nowrap",
        },
        {
          key: "empresaRazaoSocial",
          label: "Empresa",
          sortable: true,
          class: "text-left",
        },
        { key: "caixaNome", label: "Caixa", sortable: true },
        { key: "tipo", label: "Tipo", sortable: true },
        { key: "situacao", label: "Status", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      grupos: [
        "Administrador",
        "Gerente",
        "Gerente Caixa",
        "Caixa",
        "Gerente Moto",
        "Moto",
        "Garçom",
        "Caixa Mesas",
        "Estoque",
      ],
      empresa: { cod_empresa: -1 },
      caixasDisponiveis: [],
      empresasDisponiveis: [],
    };
  },
  mounted() {
    this.carregar();
    // this.carregaCaixas();
    // this.carregaEmpresas();
  },
  methods: {
    async selecionar(emp) {
      this.empresa = emp;

      this.filtrar(this.filtro);
    },
    async salvar(data, semSenha = false, isEmpresa = false) {
      this.loadingUpdatesNoTable = true;
      try {
        if (semSenha) delete data.senha;
        if (isEmpresa) {
          data.cod_caixa = this.caixasFromEmrpesa(data.cod_empresa)[0].value;
        }
        await UsuariosLib.store(data);
        await this.carregar();
      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingUpdatesNoTable = false;
      }
    },
    novo() {
      this.usuarioEdit = {};
      this.$bvModal.show("modal-usuarios");
    },
    async filtrar(filtro) {
      this.filtro = filtro;
      if (this.$props.onFiltrar) {
        this.$props.onFiltrar(this.filtro);
      }
      this.listaShow = this.lista;
      if (this.empresa.cod_empresa != -1) {
        this.listaShow = this.listaShow.filter(
          (x) => x.cod_empresa == this.empresa.cod_empresa
        );
      }
    },
    async carregar() {
      await this.carregaEmpresas();
      await this.carregaCaixas();
      this.loading = true;
      await this.$nextTick();
      this.lista = await UsuariosLib.get();

      this.filtrar(this.filtro);
      this.loading = false;
    },
    async editar(item) {
      if (item) {
        this.usuarioEdit = item;
        this.$bvModal.show("modal-usuarios");
      }
    },
    carregaCaixas() {
      let caixas = [...this.$store.state.caixas];
      this.caixasDisponiveis = caixas.map((caixa) => {
        return { text: caixa.nome, value: caixa.cod_caixa };
      });
    },
    caixasFromEmrpesa(empresa) {
      let empresas = [];
      empresas = [...this.$store.state.empresas.empresas];
      empresas.push(this.$store.state.empresas);
      if (empresas) {
        let emp = empresas.find((x) => x.cod_empresa == empresa);
        // console.log("emp", emp);

        return emp && emp.caixas
          ? emp.caixas.map((caixa) => {
              return { text: caixa.nome, value: caixa.cod_caixa };
            })
          : [];
      }
    },
    carregaEmpresas() {
      let empresas = [];
      empresas = [...this.$store.state.empresas.empresas];
      empresas.push(this.$store.state.empresas);
      empresas = empresas.sort((a, b) => {
        return a.cod_empresa - b.cod_empresa;
      });

      this.empresasDisponiveis = empresas.map((empresa) => {
        return { text: empresa.nome, value: empresa.cod_empresa };
      });
    },
    async excluir(user) {
      // console.log(user)
      if (user) {
        this.$swal
          .fire({
            title: "Você tem creteza que deseja excluir este usuário?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.loading = true;
              const result = await UsuariosLib.del(user);
              // console.log( result)
              if (result.data && result.success) {
                this.$swal.fire({
                  title: "Usuário excluído com sucesso!",
                  icon: "success",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });

                this.carregar();
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir usuário!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
              this.loading = false;
            }
          });
      }
    },
  },
};
</script>

<style></style>
